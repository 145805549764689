export const CONFIG = {
  business_username: 'layers',
  business_id: '678',
  splash_text: 'Layers Bakeshop US',
  app_version: '0.0.20',
  Station: '95537001',
  //splash_image:'assets/animation/loader.svg',
  splash_image: '',

  //vegbar  432
  //caribbeanspot 311
  // guac 442
  //datarhalal 504
  //jalapenoshack 431
  //mknxbranches 153
  //sevenbrother 401
  //mainbakhtar 240
  //pauls 436
  //mythreesonsbagel 419
  //cusmos 135
  //heavenlymain 465
  //tabconveniencestore 422
  //flatbushexotics 409
  //guactimemain 460
  //rootsmain 541
  //tropicaljerkmain 380

  //    appId: "",
  //     googleProjectId: "",
  //      marketPlace: false,
  //       company: 'mknx',
  //        env: 'production'
};
